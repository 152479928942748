import { GQLDomainQuery } from "@gql/types/gql";
import { useQuery } from "@tanstack/react-query";
import Head from "next/head";
import React, { ReactElement } from "react";
// import { useRouter } from "next/router";
interface Props {
    description?: string;
    title?: string;
    keyWords?: string;
    image?: string;
    url?: string;
}
// const locale = process.env.NEXT_PUBLIC_DEFAULT_LANG;

export default function MetaTags(props: Props): ReactElement {
    const {
        title,
        description,
        // image, keyWords, url
    } = props;
    // const query = useRouter();
    const domainName = process.env.NEXT_PUBLIC_X_DOMAIN || "Torts.com";
    const { data: domainInfo } = useQuery<GQLDomainQuery>({
        queryKey: ["domain"],
    });
    const isOgTag = (tag?: string | null) => tag?.startsWith("og:");

    const ogTags = domainInfo?.domain?.metatags?.filter(
        (tag) => isOgTag(tag?.key) && tag?.value,
    );
    const otherTags = domainInfo?.domain?.metatags?.filter(
        (tag) => !isOgTag(tag?.key) && tag?.value,
    );

    return (
        <Head>
            <title>{title}</title>
            {/* <link
                rel="canonical"
                href={`https://${domainName}${
                    url?.replace("index", "") ? url : query.asPath
                }`}
            /> */}
            <meta name="title" content={title ?? domainName} />
            <meta name="description" content={description ?? domainName} />
            {otherTags?.map(
                (tag) =>
                    tag?.key &&
                    tag.value && (
                        <meta
                            key={tag.key}
                            name={tag?.key}
                            content={tag.value}
                        />
                    ),
            )}
            {ogTags?.map(
                (tag) =>
                    tag?.key &&
                    tag.value && (
                        <meta
                            key={tag.key}
                            property={tag?.key}
                            content={tag.value}
                        />
                    ),
            )}
            <meta
                property="og:image"
                name="image"
                content="https://dwy9ix7d387oz.cloudfront.net/attachments/2023/9/f1a5068d-b25f-4254-8b25-7b43496886ca.jpg"
            />
            {/* 
            <meta name="keywords" content={keyWords ?? domainName} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="MobileOptimized" content="width" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="referrer" content="origin" />
            <meta
                name="rights"
                content={`©${new Date().getFullYear()} ${domainName}. All rights reserved.`}
            />
            <meta itemProp="name" content={title ?? domainName} />
            <meta itemProp="description" content={description ?? domainName} />
            <meta
                itemProp="image"
                content={image ?? `https://${domainName}/hero.jpg`}
            />

            <meta property="og:type" content="website" />
            <meta property="og:title" content={title ?? domainName} />
            <meta
                property="og:url"
                content={`https://${domainName}${url ? url : query.asPath}`}
            />
            <meta property="og:description" content={description ?? ""} />
            <meta
                property="og:image"
                content={image ?? `https://${domainName}/hero.jpg`}
            />
            <meta
                property="og:image:url"
                content={image ?? `https://${domainName}/hero.jpg`}
            />
            <meta property="og:site_name" content={domainName} />
            <meta property="og:locale" content={locale ?? "en"} />

            <meta property="twitter:card" content="summary_large_image" />
            <meta
                property="twitter:url"
                content={`https://${domainName}${url ? url : query.asPath}`}
            />
            <meta property="twitter:title" content={title ?? domainName} />
            <meta
                property="twitter:description"
                content={description ?? domainName}
            />
            <meta
                property="twitter:image"
                content={image ?? `https://${domainName}/hero.jpg`}
            /> */}
        </Head>
    );
}
