import {
    GQLCategories,
    GQLCategoryAttributes,
    GQLOffers,
    Maybe,
} from "@gql/types/gql";
import { WorkingHoursType } from "@genericTypes/sharedTypes";

import { GQLDomains } from "@gql/types/gql";
import parse from "html-react-parser";

export const formatPhoneNumber = (phoneNumber: string | undefined) => {
    if (!phoneNumber) return null;
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
};

export const getDate = (date: string) => {
    const dateArr = date.split("/");
    const monthArr = [
        "january",
        "february",
        "march",
        "abril",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "december",
    ];
    return `${monthArr[parseInt(dateArr[0], 10) - 1]} ${dateArr[1]},${
        dateArr[2]
    }`;
};
export const getDay = (day: number) => {
    const dayArr = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
    ];
    return `${dayArr[day]}`;
};

export const isWithinSchedule = (
    workingHours: WorkingHoursType | null | undefined,
) => {
    if (!workingHours?.isActive || workingHours === null) {
        return true;
    }

    const buyerTime = new Date(
        new Date().toLocaleString("en-US", {
            timeZone: workingHours.timeZone,
        }),
    );

    const time = `${buyerTime.getHours()}:${
        buyerTime.getMinutes() < 10
            ? `0${buyerTime.getMinutes()}`
            : buyerTime.getMinutes()
    }:${
        buyerTime.getSeconds() < 10
            ? `0${buyerTime.getSeconds()}`
            : buyerTime.getSeconds()
    }`;
    const day = getDay(buyerTime.getDay());

    const workAtThatDay = workingHours.schedule.find((el) => el.name === day);

    if (workAtThatDay?.isActive) {
        const workArr = workAtThatDay.time.map((el) => {
            return (
                parseInt(el.start.replace(/:/g, ""), 10) <=
                    parseInt(time.replace(/:/g, ""), 10) &&
                parseInt(el.end.replace(/:/g, ""), 10) >=
                    parseInt(time.replace(/:/g, ""), 10)
            );
        });

        if (workArr.includes(true)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getFirstParagraphFormPost = (body: string | undefined | null) => {
    const unfilteredChildren = parse(body ? body : "", {
        trim: true,
    });

    let children: JSX.Element[] = [];

    if (Array.isArray(unfilteredChildren)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        children = unfilteredChildren?.find((el) => el.type === "p")?.props
            .children;
    }

    let content = Array.isArray(children) ? children[0] : children;
    while (typeof content !== "string") {
        //@ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        content = content.props.children;
    }

    return content;
};

export const featureListOfferValueHandler = (value: string) => {
    const newValue = value.toLowerCase();
    const availableValues = ["true", '"true"', true];
    const notAvailableValues = ["false", '"false"', false, ""];
    if (availableValues.includes(newValue)) {
        return "Available";
    }
    if (notAvailableValues.includes(newValue)) {
        return "Not Available";
    }
    return value;
};
const getOfferAttributes = (offer: GQLOffers, groupSlug: string) => {
    return offer.attributes
        ?.filter(
            (offer) =>
                offer?.baseAttribute?.comparison &&
                offer?.baseAttribute?.groupSlug === groupSlug,
        )
        .map((offerElem) => ({
            label: offerElem?.baseAttribute?.name as string,
            value: offerElem?.value as string,
        }));
};

const getSharedCategoryOfferAttributes = (
    category: GQLCategories,
    offerAttribute: { label: string; value: string }[],
    groupSlug: string,
) => {
    const offerAttributesLabels = offerAttribute.map(
        (offerAttributeElem) => offerAttributeElem.label,
    );
    const shareAttribute: { label: string; value: string }[] = [];
    category.attributes?.forEach((attribute) => {
        if (
            attribute?.groupSlug === groupSlug &&
            !offerAttributesLabels.includes(attribute.name as string)
        ) {
            shareAttribute.push({
                label: attribute.name as string,
                value: "false",
            });
        }
    });
    return shareAttribute;
};

export const getAttributesComparisons = (
    category: GQLCategories,
    offer: GQLOffers,
    groupSlug: string,
) => {
    const offerAttribute = getOfferAttributes(offer, groupSlug) ?? [];
    const sharedCategoryAttribute: { label: string; value: string }[] =
        getSharedCategoryOfferAttributes(category, offerAttribute, groupSlug) ??
        [];

    return [...sharedCategoryAttribute, ...offerAttribute];
};
interface Group {
    [x: string]: {
        slug: string;
        name: string;
    };
}
export const getAllGroupsFromAttributes = (category: GQLCategories) => {
    const groupObject: Group = {};

    category.attributes?.forEach((element: Maybe<GQLCategoryAttributes>) => {
        if (element?.groupSlug) {
            groupObject[element.groupSlug] = {
                slug: element?.groupSlug,
                name: element?.group as string,
            };
        }
    });
    return Object.keys(groupObject).map((key) => groupObject[key]);
};
export const getDescriptionForMetaTags = (domain: GQLDomains) => {
    for (let i = 0; i < domain?.metatags!.length; i++) {
        if (
            domain?.metatags![i]?.key === "description" &&
            domain?.metatags[i]?.value
        ) {
            return domain?.metatags[i]?.value;
        }
    }
    if (domain?.description) {
        return domain.description.replace(/<[^>]+>/g, "");
    }

    return "Torts.com";
};
export const getKeyWordsForMetaTags = (domain: GQLDomains) => {
    for (let i = 0; i < domain?.metatags!.length; i++) {
        if (
            domain?.metatags![i]?.key === "keywords" &&
            domain?.metatags[i]?.value
        ) {
            return domain?.metatags[i]?.value;
        }
    }

    return undefined;
};
export const getMainTitlesNavigationFromBody = (html: string) => {
    const regex = /<[^>]+id="([^"]+)"[^>]*>([\s\S]+?)<\/[^>]+>/g;
    const result = [];

    let match;
    while ((match = regex.exec(html))) {
        const [, id, content] = match;

        const textNodes = content.match(/>([^<]+)/g);

        if (textNodes) {
            result.push(
                // eslint-disable-next-line no-unsafe-optional-chaining
                ...textNodes?.map((node) => ({
                    value: node.substring(1).trim(),
                    id,
                })),
            );
        } else {
            result.push({
                value: content.trim(),
                id,
            });
        }
    }

    return result
        .filter((i) => i.value?.length > 1)
        .map((el) => ({
            title: /^\d/.test(el.value)
                ? el.value.replace(/^\d/, "").slice(1)
                : el.value,
            link: `#${el.id}`,
        }));
};

// export const getMainTitlesNavigationFromBody = (body: string) => {
//     // split main body into array of elements which contain id and title
//     // due to the first element never contain id i remove it
//     const mainArray = body?.split("id=").slice(1);

//     const mainTitles = mainArray.map((el) => {

//         //get the link, the link is the first word between double "" in the element. example => "/"id/" name="name"> "
//         const link = el.slice(1, el.indexOf('"', 1));

//         // the title should be the closest string surrounded with <b> or <strong> elements
//         let title;

//         // get title in <strong/> element
//         const strongElementLength = 8; // ====> [ < , s , t , r , o , n , g , > ]
//         const strongEl = el.slice(
//             el.indexOf("<strong>") + strongElementLength,
//             el.indexOf("</strong>"),
//         );
//         // get title in <b/> element
//         const boldElementLength = 3; // ====> [ < , b , > ]
//         const boldEl = el.slice(
//             el.indexOf("<b>") + boldElementLength,
//             el.indexOf("</b>"),
//         );

//         // get index to know which element is closest to id
//         const indexStrongEl = el.indexOf("<strong>");
//         const indexBoldEl = el.indexOf("<b>");

//         // if index of <strong> equal -1 so title in <b> element
//         if (indexBoldEl > 0 && indexStrongEl === -1) {
//             title = boldEl;
//         }

//         // if index of <b> equal -1 so title in <strong> element
//         if (indexStrongEl > 0 && indexBoldEl === -1) {
//             title = strongEl;
//         }

//         // get title in the closest element
//         if (indexBoldEl > 0 && indexStrongEl > 0) {
//             if (indexBoldEl < indexStrongEl) {
//                 title = boldEl;
//             } else {
//                 title = strongEl;
//             }
//         }
//         //check if the element with id have string
//         if (el.slice(el.indexOf(">") + 1, el.indexOf("<")).trim()) {
//             title = el.slice(el.indexOf(">") + 1, el.indexOf("<")).trim();
//         }

//         // check if title start with number to remove it. example =====> 1.title
//         if (title && /^\d/.test(title)) {
//             title = title.replace(/^\d/, "").slice(1);
//         }

//         return { title: title, link: `#${link}` };
//     });

//     //filter main titles from undefined links and title
//     const mainTitlesFiltered = mainTitles.filter((el) => el.link && el.title);

//     return mainTitlesFiltered;
// };

export const addStyleClassToTable = (body: string) => {
    if (body) {
        // start tag replace with <div className="table-responsive" > <table
        const firstSectionStartTag = body?.split("<table")[0]; // first section dose not contain <table to replace
        const bodyAfterReplaceStartTag = body
            ?.split("<table")
            .slice(1)
            .map((table) => {
                return `<div class="table-responsive"> <table ${table}`;
            });
        // final body after replace start tag
        const bodyAfterAddingStartTag = [
            firstSectionStartTag,
            ...bodyAfterReplaceStartTag,
        ].join(" ");

        // end tag replace with </table> </div>

        const firstSectionEndTag = bodyAfterAddingStartTag.split("</table>")[0];

        const bodyAfterReplaceEndTag = bodyAfterAddingStartTag
            .split("</table>")
            .slice(1)
            .map((table) => {
                return `</table></div>${table}`;
            });
        // final body after replace

        const finalBody = [firstSectionEndTag, ...bodyAfterReplaceEndTag].join(
            " ",
        );
        return finalBody;
    } else {
        return body;
    }
};

export const getCategoriesDescription = (
    category: GQLCategories,
    domain: GQLDomains,
) => {
    for (let i = 0; i < category?.metatags!.length; i++) {
        if (
            category?.metatags![i]?.key === "description" &&
            category?.metatags[i]?.value
        ) {
            return category?.metatags[i]?.value;
        }
    }
    for (let i = 0; i < domain?.metatags!.length; i++) {
        if (
            domain?.metatags![i]?.key === "description" &&
            domain?.metatags[i]?.value
        ) {
            return domain?.metatags[i]?.value;
        }
    }

    if (category?.overview) {
        return category?.overview.replace(/<[^>]+>/g, "").substring(0, 537);
    }

    return "Several.com is a comparison platform where we aim to review and examine certain products and services to educate our readers and give them a clear and sharp insight before making any purchase";
};
export const getCategoriesKeyWords = (
    category: GQLCategories,
    domain: GQLDomains,
) => {
    for (let i = 0; i < category?.metatags!.length; i++) {
        if (
            category?.metatags![i]?.key === "keywords" &&
            category?.metatags[i]?.value
        ) {
            return category?.metatags[i]?.value;
        }
    }

    for (let i = 0; i < domain?.metatags!.length; i++) {
        if (
            domain?.metatags![i]?.key === "keywords" &&
            domain?.metatags[i]?.value
        ) {
            return domain?.metatags[i]?.value;
        }
    }

    return "vpn , auto warranty";
};
