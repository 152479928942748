import { showContactModal } from "@store/categoryStore";
import { useAtom } from "jotai";
import React, { ChangeEvent, ReactElement, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { useForm } from "react-hook-form";
import { submitContactUsForm } from "src/api/contactUs";
import Spinner from "@components/shared/spinner";
import { useRouter } from "next/router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ConfirmationCheck from "@components/shared/confirmationCheck";
export type Inputs = {
    firmName: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    city: string;
    body: string;
    interestedIn: string[];
};
import { handleUsPhoneNumberMasking } from "./utils";

export interface Field {
    name: keyof Inputs;
    label: string;
    component: "input" | "textarea" | "checkbox";
    type?: string;
    options?: {
        value: string;
        label: string;
    }[];
}

export enum ErrorCodes {
    INVALID_GOOGLE_CAPTCHE = 1,
    INVALID_PHONE_NUMBER_FORMAT = 2,
    GENERAL = 3,
}
export interface Error {
    type: ErrorCodes;
    message: string;
}

const EXTRA_FIELDS = ["interestedIn", "city", "firmName"] as const;
type ExtraFieldTuple = typeof EXTRA_FIELDS;
type ExtraField = ExtraFieldTuple[number];

function isExtraField(value: string): value is ExtraField {
    return EXTRA_FIELDS.includes(value as ExtraField);
}

export default function ContactUsForm({
    fields,
    className,
    legend,
    tcpa,
    combineNameFields,
    submitButtonMessage,
    successMessageStyle,
}: {
    fields: Field[];
    className?: string;
    legend?: string;
    tcpa?: string;
    combineNameFields?: boolean;
    submitButtonMessage?: string;
    successMessageStyle?: string;
}): ReactElement {
    const [, setModalVisibility] = useAtom(showContactModal);
    const [error, setError] = useState<Error | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();

    const getFinalFields = (data: Inputs) => {
        const defaultFormFields = {
            firstName: !combineNameFields
                ? data.firstName
                : data.firstName.split(" ")[0],
            lastName: !combineNameFields
                ? data.lastName
                : data.firstName.split(" ")[1] ?? "---",
            email: data.email,
            phone: data.phone[0] === "1" ? data.phone : `1 ${data.phone}`,
            body:
                data.body ||
                `${
                    category
                        ? `${category as string} retainers`
                        : "Contact Us Form"
                }`,
        };
        const extraFields: { key: string; value: string | string[] }[] = [];

        fields.forEach((field) => {
            if (isExtraField(field.name)) {
                extraFields.push({
                    key: field.name,
                    value: data[field.name],
                });
            }
        });

        return {
            ...defaultFormFields,
            customFields: extraFields.map((i) => {
                return {
                    key: i.key,
                    value: Array.isArray(i.value)
                        ? i.value.join(", ")
                        : i.value || "-",
                };
            }),
        };
    };

    const { query } = useRouter();
    const category = useMemo(() => {
        return query.category;
    }, [query.category]);

    const submitHandler = async (info: Inputs) => {
        // setRefreshReCaptcha((prev) => !prev);
        const token = executeRecaptcha
            ? await executeRecaptcha("contact_us_form")
            : null;
        if (!token) {
            setError({
                type: ErrorCodes.INVALID_GOOGLE_CAPTCHE,
                message: "Please verify you are not a robot.",
            });
            return;
        }
        setIsSubmitting(true);
        submitContactUsForm({
            fields: {
                ...getFinalFields(info),
                googleReCaptcha: token,
                subject: "Torts.com",
            },
        })
            .then((res) => {
                setIsSubmitting(false);

                if (res?.data?.success) {
                    setError(null);
                    setSuccess(true);
                    setTimeout(() => {
                        setModalVisibility(false);
                    }, 3000);
                } else {
                    setIsSubmitting(false);
                    setSuccess(false);

                    if (res?.data?.code === "INVALID_PHONE_NUMBER_FORMAT") {
                        setError({
                            type: ErrorCodes.INVALID_PHONE_NUMBER_FORMAT,
                            message: "Invalid phone number!!",
                        });
                    } else if (res?.data?.code === "INVALID_GOOGLE_CAPTCHE") {
                        setError({
                            type: ErrorCodes.INVALID_GOOGLE_CAPTCHE,
                            message: "Please verify you are not a robot.",
                        });
                    } else {
                        setError({
                            type: ErrorCodes.GENERAL,
                            message:
                                "An error occurred. Please try again later.",
                        });
                    }
                }
            })
            .catch(() => {
                setIsSubmitting(false);
                setSuccess(false);
                setError({
                    type: ErrorCodes.GENERAL,
                    message: "An error occurred. Please try again later.",
                });
            });
    };

    const getField = (field: Field) => {
        switch (field.component) {
            case "input":
                return (
                    <>
                        <input
                            type={field.type ? field.type : "text"}
                            placeholder={field.label}
                            {...register(field.name, {
                                required: "This field is required",
                                ...(field.name === "phone"
                                    ? {
                                          validate: (value) => {
                                              if (typeof value === "string") {
                                                  return (
                                                      value.replace(/\D/g, "")
                                                          .length >=
                                                          (value[0] === "1"
                                                              ? 11
                                                              : 10) ||
                                                      "Invalid phone number"
                                                  );
                                              } else {
                                                  return "Invalid phone number";
                                              }
                                          },
                                          onChange: (
                                              e: ChangeEvent<HTMLInputElement>,
                                          ) => {
                                              const { value } = e.target;
                                              e.target.value =
                                                  handleUsPhoneNumberMasking(
                                                      value,
                                                  );
                                          },
                                      }
                                    : {}),
                                ...(field.name === "email"
                                    ? {
                                          pattern: {
                                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                              message: "Invalid Email",
                                          },
                                      }
                                    : {}),
                            })}
                        />
                        {error?.type ===
                            ErrorCodes.INVALID_PHONE_NUMBER_FORMAT &&
                            field.name === "phone" && (
                                <p className="text-red-500 flex">
                                    {error.message}
                                </p>
                            )}
                    </>
                );
            case "textarea":
                return (
                    <textarea
                        placeholder={field.label}
                        {...register(field.name, {
                            required: "This field is required",
                        })}
                        className=" lg:h-[152px] h-[90px]"
                    />
                );
            case "checkbox":
                return (
                    <div className={styles.checkbox}>
                        <label className="mr-3 lg:mr-10 text-sm sm:text-base">
                            {field.label}
                        </label>
                        {field?.options?.map((option, index) => (
                            <div
                                key={option.value}
                                className={styles["option"]}
                            >
                                <input
                                    type="checkbox"
                                    id={`${field.name}-${option.value}`}
                                    value={option.value}
                                    {...register(field.name)}
                                    className="mx-3"
                                />
                                <label
                                    key={index}
                                    htmlFor={`${field.name}-${option.value}`}
                                    className="flex items-center "
                                >
                                    <span className={styles["box"]}>
                                        <svg viewBox="0,0,50,50">
                                            <path d="M5 30 L 20 45 L 45 5"></path>
                                        </svg>
                                    </span>
                                    <span className="text-sm">
                                        {option.label}
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                );
        }
    };

    return (
        <>
            {success ? (
                <div
                    className={`${
                        successMessageStyle as string
                    } px-5 lg:px-52 pt-20 mt-10 lg:mt-0 `}
                    id="thankYou"
                >
                    <div className=" w-fit mx-auto">
                        <ConfirmationCheck color="white" />
                    </div>

                    <div className="flex-col justify-center items-center text-center flex text-white ">
                        <h2 className="text-3xl font-bold ">Thank You</h2>
                        <p className="font-semibold mt-4">
                            Your Message Has Been Sent.
                        </p>
                        <p className="text-sm mt-1">
                            Our Support Staff Will Get Back to You Within 24
                            Hours
                        </p>
                    </div>
                </div>
            ) : (
                <form
                    onSubmit={handleSubmit(submitHandler)}
                    className={`${
                        className ?? ""
                    }  text-center w-full mx-auto flex flex-col items-center justify-center gap-4 lg:min-w-[900px]`}
                >
                    {legend && (
                        <legend className="lg:text-4xl text-2xl text-white font-bold">
                            {legend}
                        </legend>
                    )}
                    {(error?.type === ErrorCodes.INVALID_GOOGLE_CAPTCHE ||
                        error?.type === ErrorCodes.GENERAL) && (
                        <p className="text-red-500 bg-[#fff7f6] rounded border-l-[5px] w-full border-l-red-500 p-3 mt-3 sm:mt-0 text-sm sm:text-base">
                            {error.message}
                        </p>
                    )}
                    <div className="grid grid-cols-1 w-full lg:grid-cols-2 gap-7">
                        {fields.map((field) => {
                            return (
                                <fieldset
                                    key={field.name}
                                    className="flex flex-col relative"
                                >
                                    <>
                                        {getField(field)}

                                        {errors[field.name] && (
                                            <span
                                                className={`text-left text-sm text-red-400 ${styles["error-message"]}`}
                                            >
                                                * {errors[field.name]?.message}
                                            </span>
                                        )}
                                    </>
                                </fieldset>
                            );
                        })}
                    </div>
                    {tcpa && <div className={styles.tcpa}>{tcpa}</div>}
                    {/* <GoogleReCaptcha
                onVerify={(value) => {
                    console.log("🔆💢🔆💢🔆 ~ value:", value);
                    setRecaptcha(value);
                }}
                refreshReCaptcha={refreshReCaptcha}
            /> */}

                    <button
                        className="mb-4 min-w-[200px]"
                        type="submit"
                        disabled={success}
                    >
                        {isSubmitting || success ? (
                            <Spinner
                                size={30}
                                success={success}
                                color="black"
                            />
                        ) : (
                            <span>{submitButtonMessage ?? "Contact Us"}</span>
                        )}
                    </button>
                </form>
            )}
        </>
    );
}
