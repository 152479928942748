export const handleUsPhoneNumberMasking = (value: string) => {
    const cleaned = value.replace(/[^0-9]/g, "");

    if (cleaned[0] === "1" && cleaned[1] === "1") {
        const x =
            cleaned.match(
                /([1]{0,1})(1{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/,
            ) ?? "";

        return (
            (x[1] ? "1" : "") +
            (x[3].length >= 1 ? (x[1] ? " (" : "(") : "") +
            (x[3] ? x[3] : "") +
            (x[4].length >= 1 ? ") " : "") +
            (x[4] ? x[4] : "") +
            (x[5].length >= 1 ? "-" : "") +
            (x[5] ? x[5] : "")
        );
    } else {
        const x =
            cleaned.match(/([1]{0,1})([0-9]{0,3})([0-9]{0,3})([0-9]{0,4})/) ??
            "";

        return (
            (x[1] ? "1" : "") +
            (x[2].length >= 1 ? (x[1] ? " (" : "(") : "") +
            (x[2] ? x[2] : "") +
            (x[3].length >= 1 ? ") " : "") +
            (x[3] ? x[3] : "") +
            (x[4].length >= 1 ? "-" : "") +
            (x[4] ? x[4] : "")
        );
    }
};
