import React, { CSSProperties, ReactElement } from "react";
import styles from "./confirmationCheck.module.scss";

export default function ConfirmationCheck({
    color,
}: {
    color: string;
}): ReactElement {
    const style = { "--color": color ?? "black" } as CSSProperties;
    return (
        <div style={style} className={styles["success-checkmark"]}>
            <div className={styles["check-icon"]}>
                <span
                    className={`${styles["icon-line"]} ${styles["line-tip"]}`}
                ></span>
                <span
                    className={`${styles["icon-line"]} ${styles["line-long"]}`}
                ></span>
                <div className={styles["icon-circle"]}></div>
                <div className={styles["icon-fix"]}></div>
            </div>
        </div>
    );
}
